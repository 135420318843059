const RouteNames = {
  Gene: {
    Gene: 'Gene',
    HomePage: 'GeneHomePage',
    // SearchOverviewPage: 'GeneSearchOverviewPage',
    SearchPage: 'GeneSearchPage',
    GeneDetailsPage: 'GeneDetails',
    BioProjectDetailsPage: 'BioProjectDetails',
    BioSampleDetailsPage: 'BioSampleDetails',
    NucleotideDetailsPage: 'NucleotideDetails',
    nucleobase: 'nucleobase', //保护碱基表
    PhenotypeMutationDetailsPage: 'PhenotypeMutationDetails',
    AboutUsPage: 'GeneAboutUs', //关于我们
    DataStatisticPage: 'GeneDataStatistic',
    BatchSearchPage: 'GeneBatchSearchPage',
    JBrowse: 'JBrowse',
    JBrowseSearch: 'JBrowseSearch',
    ORFFinder: 'ORFFinder',
    PrimerUploadDetail: 'PrimerUploadDetail',
    PrimerHistoryMission: 'PrimerHistoryMission', //引物历史任务
    WaitingInterface: 'WaitingInterface', //引物设计中间等待界面
    PrimerOutput: 'PrimerOutput', //引物输出页
    PrimerFail: 'PrimerFail', //引物设计失败
    JBrowseDownload: 'JBrowseDownload',
    ResetMap: 'ResetMap',
  },
  Compound: {
    Compound: 'Compound',
    HomePage: 'CompoundHomePage',
    // SearchOverviewPage: 'CompoundSearchOverviewPage',
    SearchPage: 'CompoundSearchPage',
    CompoundDetailsPage: 'CompoundDetails',
    SubstanceDetailsPage: 'SubstanceDetails',
    AboutUsPage: 'CompoundAboutUs', //关于我们
    TargetPredictionPage: 'TargetPrediction',
    PeriodicTablePage: 'PeriodicTable',
    AnalogueCompoundsPage: 'AnalogueCompounds',
    ChemicalReactionPage: 'ChemicalReactionDetails',
    DataStatisticPage: 'CompoundDataStatistic',
    SimilarCompoundPage: 'SimilarCompound',
    BatchSearchPage: 'CompoundBatchSearchPage',
    StructureDrawPage: 'CompoundStructureDrawPage',
    ADMETPage: 'CompoundADMETDetails',
    AdmetPrediction: 'AdmetPrediction',
    InverseSynthesis: 'InverseSynthesis',
  },
  Nature: {
    Nature: 'Nature',
    HomePage: 'NatureHomePage',
    SearchOverviewPage: 'NatureSearchOverviewPage',
    SearchPage: 'NatureSearchPage',
    SearchPage2: 'NatureSearchPage2',
    BatchSearchPage: 'NatureBatchSearchPage',
    TestPage: 'NatureTestPage',
    CompoundDetailsPage: 'NatureCompoundDetails',
    FoodDetailsPage: 'FoodDetails',
    PlantDetailsPage: 'PlantDetails',
    AboutUsPage: 'NatureAboutUs', //关于我们
    BioassayDetails: 'BioassayDetails1',
    CellLinesDetails: 'CellLinesDetails',
    BrowserPage: 'Browser',
    DataStatisticPage: 'NatureDataStatistic',
    Taxonomy: 'Taxonomy',
    TaxonomyList: 'TaxonomyList',
    StructureDrawPage: 'NatureStructureDrawPage',
    ChemicalReactionPage: 'NatureChemicalReactionDetails',
  },
  Protein: {
    Protein: 'Protein',
    HomePage: 'ProteinHomePage',
    // SearchOverviewPage: 'ProteinSearchOverviewPage',
    SearchPage: 'ProteinSearchPage',
    SearchPage2: 'ProteinSearchPage2',
    ProteinDetailsPage: 'ProteinDetails',
    AboutUsPage: 'ProteinAboutUs',
    HumanDiseasesDetailsPage: 'HumanDiseasesDetailsPage',
    TargetDetailsPage: 'TargetDetailsPage',
    BlastPage: 'ProteinBlast', //关于我们页面,
    DataStatisticPage: 'ProteinDataStatistic',
    ClusterAnalysis: 'ClusterAnalysisPage', //聚类分析页面
    BatchSearchPage: 'ProteinBatchSearchPage',
    blastTaskbar: 'blastTaskbar', //blast任务
    Visual: 'Visual', //可视化
    PhysicochemicalPropertyAnalyze: 'PhysicochemicalPropertyAnalyze',
    AlphaFold: 'AlphaFoldDetails',
    VirtualFiltering: 'VirtualFiltering',
  },
  Home: {
    HomePage: 'HomePage ',
    HomeAllSearch: 'HomeAllSearch',
    dataSources: 'dataSources', //数据来源
    registration: 'registration', //注册页面
    MSA: 'MSA', //msa页面
    MSAResult: 'MSAResult',
    MSAMyAssignment: 'MSAMyAssignment',
    News: 'News', //新闻页面
    NewsLsit: 'NewsLsit', //新闻列表
    NewsDetail: 'NewsDetail', //详情页
    HomeDownload: 'HomeDownload', //大主页下载页
    ChildDownload: 'ChildDownload',
    NatureDownload: 'NatureDownload',
    ProteinDownload: 'ProteinDownload',
    CompoundDownload: 'CompoundDownload',
    GeneDownload: 'GeneDownload',
    SimilarCompound: 'HomeSimilarCompound', //相似化合物
    AboutUs: 'AboutUs', //关于我们
    chatgpt: 'chatgpt', //人工智能
    Mytask: 'Mytask', //我的任务
    instruction: 'instruction',
    personal: 'personal', //个人中心
    Collect: 'Collect',
    knowledgeAtlas: 'knowledgeAtlas', //知识图谱,
    SearchAll: 'SearchAll',
  },
  Login: {
    HomePage: 'LoginPage',
  },
  CoustomerService: {
    HomePage: 'CoustomerServicePage',
  },
  Generic: {
    NotFoundPage: 'NotFoundPage',
  },
  Upload: {
    Upload: 'Upload',
    UploadHome: 'UploadHome',
    UploadItem: 'UploadItem',
    UploadDetail: 'UploadDetail',
    UploadEdit: 'UploadEdit',
  },
  Show: {
    Show: 'ShowHome',
  },
}

export default RouteNames
